import { useEffect } from "react";
import Router from "next/router";
import useSWR from "swr";
import firebase from 'firebase/app'
import 'firebase/auth'
import initFirebase from 'utils/auth/initFirebase'
import fetch from 'isomorphic-unfetch'
import MyStorage from 'utils/mystorage'
import axios from 'utils/axios'

initFirebase()

const fetcher = async url => { 
  let result = await axios.get(url).then(r => { return r.data}).catch((error) => { console.log("swrerror!", error); Router.push('/login');} )
  // let result = await fetch(url).then(r => r.json())
  return result
}

export default function useUser({
  redirectTo = false,
  redirectIfFound = false,
  suspense = false,
  delay = 0
} = {}) {

  var token
  useEffect(() => {
      // Listen for auth state changes.
      const authCheck = async function(user) {
        try {
          token = await user.getIdToken()
        } catch (error) {}
      }
      const unsubscribe = firebase.auth().onAuthStateChanged(authCheck)
      return () => unsubscribe()
  }, [])

  var myCache = new MyStorage()
  var data, error, mutate = ""
  
  if (suspense && (typeof window !== 'undefined')) {
    var { data: user, error: error, mutate: mutateUser } = useSWR("/api/users?action=checkUser", fetcher, { suspense: true });
  } else {
    var { data: user, error: error, mutate: mutateUser } = useSWR("/api/users?action=checkUser", fetcher);
  }

  useEffect(() => {
    if (!redirectTo || !user) {
      return;
    }

    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && user?.isLoggedIn)
    ) {  
      setTimeout(() => {
        Router.push(redirectTo)
      }, delay);
    }
  }, [user, redirectIfFound, redirectTo]);

  return { user, mutateUser };
}